<template>
	<div class="details">
		<p class="toBack" @click="toBack" v-if="!show"><i class="el-icon-back"></i> 返回</p>
		<div class="subCard clearfix" v-if="!show">
			<div class="subCard-header">
				<p class="partitle" v-if="title">
					{{ title }}
				</p>
			</div>
			<div class="subCard-item float-l" v-for="(item, index) in peopleList" :key="index" @click="checkItem(item)">
				<div class="subCard-item-content">
					<img src="@/assets/imgs/recordForm.png" alt="" />
					<p class="title">{{ item.person.title }}</p>
					<p class="subTitle">编号：{{ item.serialNumber }}</p>
					<p class="subTitle mb0">时间：{{ item.createTime }}</p>
				</div>
			</div>
			<div class="nullData" v-if="peopleList.length == 0">暂无数据</div>
		</div>
		<FileDetails
			v-else
			v-for="(item, index) in detailsData"
			:key="index"
			ref="fileDetails"
			:showToBack="true"
			:detailsData="item"
			@toBack="show = false"
		></FileDetails>
	</div>
</template>

<script>
export default {
	data() {
		return {
			peopleList: [],
			title: '',
			show: false,
			detailsData: [],
		};
	},
	components: {
		FileDetails: () => import('@/components/FileDetails/index.vue'),
	},

	mounted() {
		if (this.$route.query.id) {
			this.init(this.$route.query);
		}
	},
	methods: {
		init({ id, link }) {
			if (!link) return this.$message.warning('请求接口失败');
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.peopleList = res.data.collection || [];
						this.title = null;
					}
				})
				.catch((e) => {});
		},
		// 查看详情
		checkItem(item) {
			if (item.notation && item.notation != '{}') {
				this.show = true;
				let data = JSON.parse(item.notation);
				data.title = item.person.title + '-体检表';
				if (data && data.items.length != 0) {
					this.detailsData = [JSON.parse(JSON.stringify(data))];
				}
			} else {
				this.$message.warning('暂无数据');
			}
		},
		toBack() {
			this.$router.push({
				path: '/familyDoctorManager/physicalExaminationManager',
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.details {
	background-color: #fff;
	border-radius: 2px;
	margin: 16px;
	padding-bottom: 40px;
	padding-top: 20px;
}
.toBack {
	width: 68px;
	color: #333;
	background-color: #f5f5f5;
	text-align: center;
	font-size: 12px;
	height: 28px;
	line-height: 28px;
	margin-right: 28px;
	border-radius: 4px;
	margin-bottom: 32px;
	margin-left: 40px;
	cursor: pointer;
	.el-icon-back {
		margin-right: 8px;
	}
}
.subCard {
	padding: 0 40px;
	&-header {
		display: flex;
	}
	.partitle {
		width: 170px;
		text-align: center;
		background-color: #f5f5f5;
		border-radius: 14px;
		color: #333;
		font-size: 12px;
		line-height: 28px;
		margin-bottom: 32px;
	}
	&-item {
		width: calc((100% - 120px) / 5);
		border: 1px solid #dde6f3;
		border-radius: 8px;
		margin-bottom: 30px;
		position: relative;
		margin-right: 30px;
		&:hover {
			box-shadow: -1px 4px 7px 0px rgba(81, 81, 81, 0.08);
		}
		&:nth-child(5n + 1) {
			margin-right: 0;
		}
		&-content {
			padding: 24px;
			text-align: center;
			cursor: pointer;
			img {
				margin-bottom: 16px;
			}
			.title {
				line-height: 22px;
				margin-bottom: 30px;
				height: 44px;
			}
			.subTitle {
				line-height: 12px;
				font-size: 12px;
				color: #999999;
				margin-bottom: 16px;
			}
		}
	}
	.mb0 {
		margin-bottom: 0;
	}
	.nullData {
		text-align: center;
		color: #999999;
		font-size: 20px;
		margin-top: 40px;
	}
}
</style>